:root {
	--pilsener-100: #FEFFEE;
	--pilsener-200: #FCFFDD;
	--pilsener-300: #F9FFBA;
	--pilsener-400: #F7FF98;
	--pilsener-500: #F4FF75;
	--pilsener-600: #F1FF53;
	--pilsener-700: #C1CC42;
	--pilsener-800: #919932;
	--pilsener-900: #606621;
	--pilsener-1000: #303311;

	--cadmium-yellow-100: #FFFEE6;
	--cadmium-yellow-200: #FFFDCC;
	--cadmium-yellow-300: #FFFB99;
	--cadmium-yellow-400: #FFF866;
	--cadmium-yellow-500: #FFF633;
	--cadmium-yellow-600: #FFF400;
	--cadmium-yellow-700: #CCC300;
	--cadmium-yellow-800: #999200;
	--cadmium-yellow-900: #666200;
	--cadmium-yellow-1000: #333100;

	--spectra-yeloow-100: #FEF8E8;
	--spectra-yeloow-200: #FDF0D0;
	--spectra-yeloow-300: #FBE2A1;
	--spectra-yeloow-400: #FAD373;
	--spectra-yeloow-500: #F8C544;
	--spectra-yeloow-600: #F6B615;
	--spectra-yeloow-700: #C59211;
	--spectra-yeloow-800: #946D0D;
	--spectra-yeloow-900: #624908;
	--spectra-yeloow-1000: #624908;

	--orange-bell-pepper-100: #FFF3EC;
	--orange-bell-pepper-200: #FFE7D9;
	--orange-bell-pepper-300: #FFCFB3;
	--orange-bell-pepper-400: #FFB68E;
	--orange-bell-pepper-500: #FF9E68;
	--orange-bell-pepper-600: #FF8642;
	--orange-bell-pepper-700: #CC6B35;
	--orange-bell-pepper-800: #995028;
	--orange-bell-pepper-900: #66361A;
	--orange-bell-pepper-1000: #331B0D;

	--vibrant-orange-100: #FFF1E9;
	--vibrant-orange-200: #FFE2D2;
	--vibrant-orange-300: #FFC6A6;
	--vibrant-orange-400: #FFA979;
	--vibrant-orange-500: #FF8D4D;
	--vibrant-orange-600: #FF7020;
	--vibrant-orange-700: #CC5A1A;
	--vibrant-orange-800: #994313;
	--vibrant-orange-900: #662D0D;
	--vibrant-orange-1000: #331606;

	--dynamite-100: #FFECE9;
	--dynamite-200: #FFD8D3;
	--dynamite-300: #FFB2A7;
	--dynamite-400: #FF8B7C;
	--dynamite-500: #FF6550;
	--dynamite-600: #FF3E24;
	--dynamite-700: #CC321D;
	--dynamite-800: #992516;
	--dynamite-900: #66190E;
	--dynamite-1000: #330C07;

	--red-stop-100: #FFE9E9;
	--red-stop-200: #FFD4D4;
	--red-stop-300: #FEA8A8;
	--red-stop-400: #FE7D7D;
	--red-stop-500: #FD5151;
	--red-stop-600: #FD2626;
	--red-stop-700: #CA1E1E;
	--red-stop-800: #981717;
	--red-stop-900: #650F0F;
	--red-stop-1000: #330808;

	--in-the-red-100: #FFE8EB;
	--in-the-red-200: #FFD2D7;
	--in-the-red-300: #FFA4AF;
	--in-the-red-400: #FF7787;
	--in-the-red-500: #FF495F;
	--in-the-red-600: #FF1C37;
	--in-the-red-700: #CC162C;
	--in-the-red-800: #991121;
	--in-the-red-900: #660B16;
	--in-the-red-1000: #33060B;

	--scarlet-cattleya-orchid-100: #F9E6EE;
	--scarlet-cattleya-orchid-200: #F2CCDC;
	--scarlet-cattleya-orchid-300: #E599B9;
	--scarlet-cattleya-orchid-400: #D96696;
	--scarlet-cattleya-orchid-500: #CC3373;
	--scarlet-cattleya-orchid-600: #BF0050;
	--scarlet-cattleya-orchid-700: #990040;
	--scarlet-cattleya-orchid-800: #730030;
	--scarlet-cattleya-orchid-900: #4C0020;
	--scarlet-cattleya-orchid-1000: #260010;

	--fuchsia-fever-100: #FFEDF5;
	--fuchsia-fever-200: #FFDBEA;
	--fuchsia-fever-300: #FFB8D6;
	--fuchsia-fever-400: #FE94C1;
	--fuchsia-fever-500: #FE71AD;
	--fuchsia-fever-600: #FE4D98;
	--fuchsia-fever-700: #CB3E7A;
	--fuchsia-fever-800: #982E5B;
	--fuchsia-fever-9h00: #661F3D;
	--fuchsia-fever-1000: #330F1E;

	--pisces-vivid-amethyst-100: #F7EDFD;
	--pisces-vivid-amethyst-200: #EEDAFB;
	--pisces-vivid-amethyst-300: #DDB5F7;
	--pisces-vivid-amethyst-400: #CD90F4;
	--pisces-vivid-amethyst-500: #BC6BF0;
	--pisces-vivid-amethyst-600: #AB46EC;
	--pisces-vivid-amethyst-700: #8938BD;
	--pisces-vivid-amethyst-800: #672A8E;
	--pisces-vivid-amethyst-900: #441C5E;
	--pisces-vivid-amethyst-1000: #220E2F;

	--sea-grape-100: #EBE6F7;
	--sea-grape-200: #D7CCEF;
	--sea-grape-300: #AF99DF;
	--sea-grape-400: #8866CE;
	--sea-grape-500: #6033BE;
	--sea-grape-600: #3800AE;
	--sea-grape-700: #2D008B;
	--sea-grape-800: #220068;
	--sea-grape-900: #160046;
	--sea-grape-1000: #0B0023;

	--cloisonne-100: #E6F1F7;
	--cloisonne-200: #CCE3EF;
	--cloisonne-300: #99C6DF;
	--cloisonne-400: #66AAD0;
	--cloisonne-500: #338DC0;
	--cloisonne-600: #0071B0;
	--cloisonne-700: #005A8D;
	--cloisonne-800: #00446A;
	--cloisonne-900: #002D46;
	--cloisonne-1000: #001723;

	--bayern-blue-100: #E6F6FB;
	--bayern-blue-200: #CCECF6;
	--bayern-blue-300: #99D9EE;
	--bayern-blue-400: #66C7E5;
	--bayern-blue-500: #33B4DD;
	--bayern-blue-600: #00A1D4;
	--bayern-blue-700: #0081AA;
	--bayern-blue-800: #00617F;
	--bayern-blue-900: #004055;
	--bayern-blue-1000: #00202A;

	--electron-blue-100: #E6F2FB;
	--electron-blue-200: #CCE5F7;
	--electron-blue-300: #99CBEF;
	--electron-blue-400: #66B0E7;
	--electron-blue-500: #3396DF;
	--electron-blue-600: #007CD7;
	--electron-blue-700: #0063AC;
	--electron-blue-800: #004A81;
	--electron-blue-900: #003256;
	--electron-blue-1000: #00192B;

	--fern-green-100: #E6F3ED;
	--fern-green-200: #CCE7DA;
	--fern-green-300: #99CFB5;
	--fern-green-400: #66B891;
	--fern-green-500: #33A06C;
	--fern-green-600: #008847;
	--fern-green-700: #006D39;
	--fern-green-800: #00522B;
	--fern-green-900: #00361C;
	--fern-green-1000: #001B0E;

	--candy-green-100: #EBFBE6;
	--candy-green-200: #D7F6CC;
	--candy-green-300: #AFED99;
	--candy-green-400: #87E466;
	--candy-green-500: #5FDB33;
	--candy-green-600: #37D200;
	--candy-green-700: #2CA800;
	--candy-green-800: #217E00;
	--candy-green-900: #165400;
	--candy-green-1000: #0B2A00;

	--delicious-dill-100: #F2FAE6;
	--delicious-dill-200: #E5F4CC;
	--delicious-dill-300: #CBEA99;
	--delicious-dill-400: #B1DF66;
	--delicious-dill-500: #97D533;
	--delicious-dill-600: #7DCA00;
	--delicious-dill-700: #64A200;
	--delicious-dill-800: #4B7900;
	--delicious-dill-900: #325100;
	--delicious-dill-1000: #192800;

	--black-slug-100: #EAE9E8;
	--black-slug-200: #D6D2D0;
	--black-slug-300: #ADA5A1;
	--black-slug-400: #837973;
	--black-slug-500: #5A4C44;
	--black-slug-600: #311F15;
	--black-slug-700: #271911;
	--black-slug-800: #1D130D;
	--black-slug-900: #140C08;
	--black-slug-1000: #0A0604;

	--stone-cold-100: #EEEEEE;
	--stone-cold-200: #DDDDDD;
	--stone-cold-300: #BBBBBB;
	--stone-cold-400: #9A9A9A;
	--stone-cold-500: #787878;
	--stone-cold-600: #565656;
	--stone-cold-700: #454545;
	--stone-cold-800: #343434;
	--stone-cold-900: #222222;
	--stone-cold-1000: #111111;

	--celandine-green-100: #F8F8F7;
	--celandine-green-200: #F1F2EF;
	--celandine-green-300: #E3E5DF;
	--celandine-green-400: #D4D7D0;
	--celandine-green-500: #C6CAC0;
	--celandine-green-600: #B8BDB0;
	--celandine-green-700: #93978D;
	--celandine-green-800: #93978D;
	--celandine-green-900: #4A4C46;
	--celandine-green-1000: #4A4C46;

	--dire-wolf-100: #E9E9E9;
	--dire-wolf-200: #D4D4D4;
	--dire-wolf-300: #A9A9A9;
	--dire-wolf-400: #7D7D7D;
	--dire-wolf-500: #525252;
	--dire-wolf-600: #272727;
	--dire-wolf-700: #1F1F1F;
	--dire-wolf-800: #171717;
	--dire-wolf-900: #101010;
	--dire-wolf-1000: #080808;
}