@import 'https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css';
@import './styles/paletas';
@import './styles/fonts';
@import url('./modal.scss');

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  border: 0;
}

body {
  font-family: 'Inter', sans-serif;
  background-color: #171E3A;
}

.font-outfit {
  font-family: 'Inter', sans-serif;
}

.container__primary {
  max-width: 1344px;
  margin: 0 auto;

  @media only screen and (max-width: 1407px) and (min-width: 1216px) {
    max-width: 85%;
  }

  @media only screen and (max-width: 1215px) {
    max-width: 90%;
  }

  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
}

.header {
  height: 284px;
  background-position: 0 35%;
  background-size: cover;
  // border-bottom: 8px solid #052d66;
  background-position: center;
  background-image: url('./assets/header/banner.png');

  @media only screen and (max-width: 940px) {
    background-size: cover;
    border-bottom: none;
    background-position: center;
    background-image: url('./assets/header/banner-tablet-mobile.png');
  }
}

.two_columns {
  display: grid;
  grid-template-columns: 50% 50%;
  height: 100%;

  @media only screen and (width< 1024px) {
    grid-template-columns: 100%;
  }
}

.profile {
  margin: 0 auto;
  position: absolute;
  left: -220px;
  right: 0;
  bottom: 0;

  @media only screen and (max-width: 940px) {
    bottom: -80px;
    left: 0;
  }

  height: 170px;
  width: 170px;
  object-fit: cover;
}

#container__seciton {
  column-count: 2;
  gap: 25px;

  @media only screen and (width< 1024px) {
    column-count: 1;
    margin-bottom: 0 !important;
  }
}

.is-hover-btn {
  &:hover {
    filter: brightness(0.9);
  }
}

.contac-links-container {
  @media only screen and (max-width: 1023px) and (min-width: 769px) {
    gap: 10px;

    .contac-links {
      gap: 10px !important;
    }
  }
}

.content__icon {
  cursor: pointer !important;
  min-width: 70px !important;
}

.btn-link {
  cursor: pointer;
}

li::marker {
  color: var(--red-stop-700);
}

// .s-hover-btn-secondary {
// 	&:hover {
// 		border: 5px solid var(--red-stop-700) !important;
// 	}
// }

.formation-mobile {
  @media only screen and (max-width: 600px) {
    flex-direction: column !important;

    img {
      display: none;
    }
  }
}

.service-slide {
  height: 140px;

  @media only screen and (max-width: 600px) {
    height: 184px;
  }
}

.service-slide-consultoria {
  height: 474px;

  @media only screen and (max-width: 600px) {
    height: 300px;
  }
}

.copy-btn {
  cursor: pointer;
}

// Alert CSS

/* The alert message box */
.alert {
  color: #fff;
  background-color: #04aa6d;
  margin-bottom: 15px;
  padding: 20px;
  position: fixed;
  width: 100%;
  bottom: -15px;
  left: 0;
  z-index: 100;
  opacity: 0;
  transition: ease-in-out 0.5s;
}

/* The close button */
.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

/* When moving the mouse over the close button */
.closebtn:hover {
  color: black;
}

.deco-slider-container {
  gap: 45px;

  @media only screen and (max-width: 940px) {
    gap: 15px;
  }
}

.deco-slider-pic {
  width: 70px;

  @media only screen and (max-width: 940px) {
    width: 70px;
  }
}

.add-to-button {
  padding: 1.5rem 0;

  @media only screen and (max-width: 940px) {
    padding: 1.5rem;
  }
}

.qr-border {
  box-shadow: none !important;
  border: 1px solid #bbb;

  @media only screen and (max-width: 940px) {
    border: none;
  }
}

.about-us-container {
  // background: linear-gradient(0deg, #052d66 44.55%, #020426 110.69%);
  background-image: url(./assets/about-us/about-us-bg.png);
  // padding: 30px 50px;
  position: relative;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and (max-width: 940px) {
    padding: 33px;
    margin-top: 1rem;
    background-position: -20px;
  }
}

.ul-modal {
  list-style-type: none;
}

.ul-modal li::before {
  content: "■";  /* Agrega tu propio marcador */
  color: #000;     /* Cambia el color del marcador */
  display: inline-block;  
  width: 1em;     /* Espacio para el marcador */
  margin-left: -1em;   /* Alinea el marcador con el texto */
}